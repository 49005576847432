<template>
  <div class="main">
    <img :src="require('../assets/images/bg1.jpg')" class="bg" />
    <viewHeader/>
    <div class="" v-if="!showPending">
    <form v-on:submit.prevent="proceedToPayment()">

      <div class="row" v-if="!isRequired">
        <div class="cart-header modal-header">
          <div class="cart-title modal-title">
            <!-- <Timer/> -->
          </div>
        </div>
        <div class="l-modal">
          <h4 class="d-flex justify-content-center text-white">ORDER SUMMARY</h4>
          <div v-if="cart">
            <div
              class="card-title d-flex justify-content-between text-uppercase"
            >
              <h5>DETAILS:</h5>
              
            </div>
            <div class="d-flex justify-content-between">
              
              <h5>You bought: </h5>
              
              <h5>{{cryptoBought}}  {{cart.fromCurrency}}
                <!-- <span class="text-muted">less fees</span>  -->
                <img
                :src="cart.image"
                  class="store-img"
                /></h5>
            </div>
            <div class="d-flex justify-content-between mb-3">
              <h6 for="lockedPrice">Locked Price</h6>
              <h5 class="card-text text-muted">
              {{formatAmount(cart.lockedPrice)}}
              </h5>
            </div>
            <hr />
            <div class="card-footer mb-3">

              <div class="d-flex justify-content-between">
                <h5 for="order">You will receive in <span class="text-small"> {{cart.fromCurrency}}</span></h5>
                <h5>{{cryptoReceive}}</h5>
              </div>
              
              <div class="d-flex justify-content-between mb-2">
                <h5 id="currency">You will pay in <span class="text-small">{{cart.toCurrency}}</span></h5>
                <h5 id="totalAmount">{{ formatAmount(amountDue)}}</h5>
              </div>
              <!-- <div class="d-flex justify-content-between mb-2">
                <h6 id="currency">VAT <span class="text-small">({{vatRate}}) % </span></h6>
                <h6 id="totalAmount">{{ formatAmount(vatAmount)}}</h6>
              </div> -->

              <div class="form-group">
                <label> Wallet Address</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="walletAddress"
                        placeholder="Enter your wallet address."
                        required/>
                      
        
              </div>
              <div class="form-group">
                <label> Transaction Comment</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="comment"
                        placeholder="Comment"
                        required/>
                      
              </div>
              
            </div>
          </div>
        </div>
      </div>



    
    
    
      <div class="row" v-if="!showPending && isRequired">
        <div class="cart-header modal-header">
          <div class="cart-title modal-title">
            <!-- <Timer/> -->
          </div>
        </div>
        <div class="column">
          <div class="l-modal">
            <h4 class="d-flex justify-content-center text-white">ORDER SUMMARY</h4>
            <div v-if="cart">
              <div
                class="card-title d-flex justify-content-between text-uppercase"
              >
                <h5>DETAILS:</h5>
                
              </div>
              <div class="d-flex justify-content-between">
                
                <h5>You bought: </h5>
                
                <h5>{{cryptoBought}}  {{cart.fromCurrency}}
                  <!-- <span class="text-muted">less fees</span>  -->
                  <img
                  :src="cart.image"
                    class="store-img"
                  /></h5>
              </div>
              <div class="d-flex justify-content-between mb-3">
                <h6 for="lockedPrice">Locked Price</h6>
                <h5 class="card-text text-muted">
                {{formatAmount(cart.lockedPrice)}}
                </h5>
              </div>
              <hr />
              <div class="card-footer mb-3">

                <div class="d-flex justify-content-between">
                  <h5 for="order">You will receive in <span class="text-small"> {{cart.fromCurrency}}</span></h5>
                  <h5>{{cryptoReceive}}</h5>
                </div>
                
                <div class="d-flex justify-content-between mb-2">
                  <h5 id="currency">You will pay in <span class="text-small">{{cart.toCurrency}}</span></h5>
                  <h5 id="totalAmount">{{ formatAmount(amountDue)}}</h5>
                </div>
                <div class="d-flex justify-content-between mb-2">
                  <h6 id="currency">VAT <span class="text-small">({{vatRate}}) % </span></h6>
                  <h6 id="totalAmount">{{ formatAmount(vatAmount)}}</h6>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="l-modal">
            <h4 class="d-flex justify-content-center text-white">RECIPIENT DETAILS</h4>
            <p class="error-text">{{error.walletAddress}}</p>
            <fieldset>
              <div class="form-group">
                <label> Wallet Address</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="walletAddress"
                        placeholder="Enter your wallet address."
                        required/>
                      
        
              </div>
            </fieldset>
            <fieldset>
              <div class="box2">
                <label for="message">Beneficiary Name:</label>
                <input
                type="text"
                class="form-control"
                v-model="recipientName"
                placeholder="Beneficiary fullname"
                required
              />
              </div>
                <div class="box2 mobile-container">
                  <label for="business">Nationality:<strong>*</strong></label>
                  <div class="input-group mb-3">
                    <input type="text" class="form-select" id="formGroupExampleInput" v-model="recipientNationality"  v-on:click="toggleCountryListForNationality">
                  </div>
                  <div class="country-list" v-if="showCountryForNationality">
                    <div class="row">
                      <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForNationality(l)" >
                        <div>
                          <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                        </div>
                        <div class="country-name">
                          <span> {{ l.country }} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </fieldset>
            <fieldset>
              <div class="box2">
                <label for="walletAddress">Rel to the recipient:</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="recipientRel"
                        placeholder="Relationship to the Beneficiary"
                        required
                      />
              </div>
              <div class="box2">
                <label for="walletAddress">Purpose of transaction:</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="purposeOfTxn"
                  placeholder="Purpose of transaction"
                  required
                />
              </div>
            </fieldset>
            <fieldset>
              <label for="walletAddress">Wallet/ Exchange Platform:</label>
              <div class="d-block m-1 justify-content-evenly" >
                <select name="profile" @change="onSelectRecipientAcct($event)" v-model="recipientAccount" class="form-select form-control" >
                  <option value="" >Select Wallet/ Exchange Platform</option>
                <option class="cardProvider d-block" style="width: 100%;" value="BINANCE">BINANCE <div class="text-small"> </div></option>
                <option class="cardProvider d-block" style="width: 100%;" value="BITSTAMP" >BITSTAMP<div class="text-small"> </div></option>
                <option class="cardProvider d-block" style="width: 100%;" value="BITFINEX" >BITFINEX <div class="text-small"></div></option>
                <option class="cardProvider d-block" style="width: 100%;" value="TRUST WALLET">TRUST WALLET <div class="text-small"> </div></option>
                <option class="cardProvider d-block" style="width: 100%;" value="OKX" >OKX <div class="text-small"> </div></option>
                <option class="cardProvider d-block" style="width: 100%;" value="OTHERS" >OTHERS<div class="text-small"></div></option>

              </select>
              <div v-if="recipientAccount == 'OTHERS'">
                <input class="form-control" v-model="recipientAccountOther"/>
              </div>
        
              </div>
            </fieldset>
          </div>
          </div>
        </div>


   
      <div class="row container-fluid" v-if="!showPending">
        <div >
          <h4 class="text-uppercase d-flex text-white text-left">
            payment options:
          </h4>
          <p class="error-text">{{error.msg}}</p>
          <div class="" >
            <form v-if="cart.toCurrency == 'USD'">
              <div class="form-check px-5" >
                <input
                @change="onChange($event)"
                  class="form-check-input"
                  v-model="selectedPayment"
                  type="radio"
                  value="BANK-USD"
                />
                <label class="form-check-label">
                BANK
                </label>
              </div>
            </form>
            <form class="payment" v-if="cart.toCurrency == 'PHP'">
            
              <div v-for="provider in list.providers" :key="provider.id">

              <div class="form-check px-5" >
                <input
                @change="onChange($event)"
                  class="form-check-input"
                  v-model="selectedPayment"
                  type="radio"
                  :value="provider"
                  
                />
                <label class="form-check-label">
                {{provider}}
                </label>
              
              </div>

              </div>
            </form>

              <div v-if="selectedPayment != 'BANK' && selectedPayment != 'BANK-USD'">
                            <select name="profile" @change="onSelect($event)" v-model="selectedMethod" class="form-select form-control" >
                              <option value="" >Select method</option>
                              <option v-for="method in list.methods" :key="method.id" :value="method">{{method}}</option> 
                          </select>
                </div>

                <div v-if="selectedPayment == 'BANK'">
                <div class="d-block m-1 justify-content-evenly" >
                <select name="profile" @change="onSelectBank($event)" v-model="bankDetails" class="form-select form-control" >
                  <option value="" >Select Bank</option>
                  <option class="cardProvider d-block" style="width: 100%;" value="UNIONBANK">Unionbank <div class="text-small"> </div></option>
                  <option class="cardProvider d-block" style="width: 100%;" value="CTBC" disabled>China Trust Bank Corp <div class="text-small"></div></option>
                  <option class="cardProvider d-block" style="width: 100%;" value="STERLING" disabled>Sterling Bank <div class="text-small"></div></option>
                </select>
          
                </div>

                <b-card v-if="bankDetails == 'UNIONBANK'">
                  <h5>BANK TRANSFER DETAILS:</h5>
                  <div class="bank text-dark">
                    <h5>Bank Name:</h5>
                    <h5>Unionbank of the Philippines</h5>
                  </div>
                  <div class="bank text-dark">
                    <h5>Account Name:</h5>
                    <h5>Aba Global Philippines Inc</h5>
                  </div>
                  <div class="bank text-dark">
                    <h5>Currency:</h5>
                    <h5>PHP</h5>
                  </div>
                  <div class="bank text-dark">
                    <h5>Account Number:</h5>
                    <h5>102730016600</h5>
                  </div>
                  <div class="bank text-dark">
                    <h5>Branch:</h5>
                    <h5>One Global Place 25th St. 5th Ave. Fort Bonifacio, BGC, Taguig City, 1634</h5>
                  </div>
                  <div class="card-body text-dark">
                    <b-card class="py-4">
                      <p>Kindly send us a receipt of your transaction via email <a href="mailto:deposit@coexstar.com" style="color: black; text-decoration: none;">deposit@coexstar.com</a> validate your cash deposit request.

                        The standard turnaround time for deposit is 5-15 minutes.

                        Please be advised that all the transactions are manually reviewed and this may cause delays. Kindly indicate your Reference Number as the subject.</p>
                    </b-card>
                
                  </div>
                </b-card>
                </div>

                <div v-if="cart.toCurrency == 'USD'">
                <div v-if="selectedPayment == 'BANK-USD'">
                  <div class="d-block m-1 justify-content-evenly" >
                  <select name="profile" @change="onSelectBank($event)" v-model="bankDetails" class="form-select form-control" >
                    <option value="" >Select Bank</option>
                    <option class="cardProvider d-block" style="width: 100%;" value="UNIONBANK">Unionbank <div class="text-small"> </div></option>
                  
                  </select>
            
                  </div>
  
                  <b-card v-if="bankDetails == 'UNIONBANK'">
                    <h5>BANK TRANSFER DETAILS:</h5>
                    <div class="bank text-dark">
                      <h5>Bank Name:</h5>
                      <h5>Unionbank of the Philippines</h5>
                    </div>
                    <div class="bank text-dark">
                      <h5>Account Name:</h5>
                      <h5>Aba Global Philippines Inc</h5>
                    </div>
                    <div class="bank text-dark">
                      <h5>Currency:</h5>
                      <h5>USD</h5>
                    </div>
                    <div class="bank text-dark">
                      <h5>Account Number:</h5>
                      <h5>1327 3000 1850</h5>
                    </div>
                    <div class="bank text-dark">
                      <h5>Branch:</h5>
                      <h5>One Global Place 25th St. 5th Ave. Fort Bonifacio, BGC, Taguig City, 1634</h5>
                    </div>
                    <div class="card-body text-dark">
                      <b-card class="py-4">
                        <p>Kindly send us a receipt of your transaction via email <a href="mailto:deposit@coexstar.com" style="color: black; text-decoration: none;">deposit@coexstar.com</a> validate your cash deposit request.
  
                          The standard turnaround time for deposit is 5-15 minutes.
  
                          Please be advised that all the transactions are manually reviewed and this may cause delays. Kindly indicate your Reference Number as the subject.</p>
                      </b-card>
                  
                    </div>
                  </b-card>
                  </div>
                  </div>



          </div>

        
        </div>
      
    </div>
    <div class="btn-back d-flex">
      <div >
        <button class="btn btn-primary"  type="submit">
          Proceed
        </button>
      </div>
    </div>
  </form>
  <div class="btn-back d-flex">
    <div >
      <button @click="exitModal" class="btn btn-primary">Back</button>
    </div>
  </div>

  </div>
 

  

    <div v-if="showPending" >
      <h5 class=" text-white">Pending</h5>
      <div class="row">
        <h5 class="card-title text-uppercase mb-3 text-white">Order summary</h5>
        <div class="column">
          <div class="l-modal">
            <div class="d-flex justify-content-between">
              <div>
                <h5 for="product-tag">You will receive: </h5>
              </div>
              <h5 for="product-price">  <img :src="cart.image"
                class="store-img"
              /> {{receiptCrypto}} 
                {{receiptCryptoAmount}}</h5>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="l-modal">
            <div class="d-flex justify-content-between">
              <div>
                <h5 for="product-tag">You will pay: </h5>
              </div>
              <h5 for="product-price">{{receiptFiat}}
                {{formatAmount(receiptFiatAmount / xchgRate)}}</h5>
            </div>

          </div>
        </div>
      </div>
     <div class="row">
      <div class="column">
        <div class="l-modal">
          <div class="bank text-white">
            <div class="bank text-white">
              <h5 class="label">Txn ID:</h5>
              <h5>{{txnId}}</h5>
            </div>
            <h5 class="label">Date and Time:</h5>
            <h5>{{receiptTxnDate}}</h5>
          </div>
          <div class="bank text-white">
            <h5 class="label">Locked Price:</h5>
            <h5>{{formatAmount(receiptLockedPrice)}}</h5>
          </div>
         
          <div class="bank text-white">
            <h5 class="label">Payment Method:</h5>
            <h5>{{receiptPaymentMethod}} - {{receiptProvider}}</h5>
          </div>
          <div class="bank text-white">
            <h5 class="label">Reference Number:</h5>
            <h5>{{receiptReferenceId}}</h5>
          </div>
          <div class="bank text-white">
            <h5 class="label">Status:</h5>
            <h5>{{receiptPaymentStatus}}</h5>
          </div>
          
          
       
      </div>
      </div>
      <div class="column">
        <div class="l-modal">
          <div class="bank text-white">
            <div class="bank text-white">
              <h5 class="label">Recipient Wallet Address:</h5>
            <h5>{{receiptWalletAddress}}</h5>
          </div>
            <h5 class="label">Recipient Name:</h5>
            <h5>{{recipientName}}</h5>
          </div>
          <div class="bank text-white">
            <h5 class="label">Recipient Nationality:</h5>
            <h5>{{recipientNationality}}</h5>
          </div>
          <div class="bank text-white">
            <h5 class="label">Relationship to the recipient:</h5>
            <h5>{{recipientRel}}</h5>
          </div>
          <div class="bank text-white">
            <h5 class="label">Destination Wallet:</h5>
            <h5>{{recipientAccount}}</h5>
          </div>
          <div class="bank text-white">
            <h5 class="label">Purpose of Transaction:</h5>
            <h5>{{purposeOfTxn}}</h5>
          </div>
        </div>
      </div>
      <h5 class="text-white" v-if="receiptPaymentMethod = 'BANK'">Kindly send us a receipt of your transaction via email <a href="mailto:deposit@coexstar.com" style="color: red; text-decoration: none;">deposit@coexstar.com</a> validate your cash deposit request. The standard turnaround time for deposit amounting to Php50,000 and below is 5-15 minutes. Please be advised that all the transactions are manually reviewed and this may cause delays. Kindly indicate your Reference Number as the subject.</h5>
     </div class="p-3">
        <b-button style="float: right"
        class="m-2"
        @click="done()">
        done
      </b-button>
    </div>

    <Exit @close="exitModal" v-if="showExitModal"/>
    <PriceReset v-if="lockedPriceReset"/>
    <LogoutModal @close="openLogout" v-if="showLogoutModal"/>
  </div>
 
  </div>

</template>

<script>
  import numeral from 'numeral'
  import Exit from './modals/Exit.vue'
  import PriceReset from './modals/PriceReset.vue'
  import LogoutModal from './modals/LogoutModal.vue'
  import Timer from "./modals/Timer.vue"
  import viewHeader from '../views/viewHeader.vue'
  import moment from 'moment'
  
export default {
  components: { Exit, PriceReset, LogoutModal, Timer, viewHeader},
    name: "Checkout",
    data() {
      return {
        isRequired: false,
        nationality: 'Philippines',
        status:'',
        timestamp: '',
        walletAddress: '',
        comment: '',
        payment: '',
        trxId: '',
        terms: '',
        cartId: '',
        provider:'',
        referenceId: '',
        showExitModal: false,
        cryptoBought:'',
        cryptoReceive:'',
        showExitModal: false,
        sourceCurrency: 'PHP',
        txnControlId:'',
        amountDue:'',
        txnId:'',
        confirmResponses: {},
        state: 'init',
        error:{
          show: false,
          walletAddress:'',
          msg:''

        },
        isEditing: false,
        lockedPriceReset: false,
        showLogoutModal: false,
        selectedPayment: 'DRAGONPAY',
        list:{
            providers: [],
            methods:[]
        },
        selectedMethod:"",
        bankDetails:"",
        receiptCrypto: '',
       receiptPaymentMethod: '',
       receiptTxnDate:'',
       receiptPaymentStatus:'',
       receiptFiat:'',
       receiptCryptoAmount:'',
       receiptFiatAmount:'',
       receiptWalletAddress:'',
       receiptComment:'',
       receiptReferenceId:'',
       recipientAccountOther:'',
       receiptLockedPrice: '',
       showPending: false,
       countdown: 60,
       vatRate: 12,
       recipientName:'',
       recipientNationality:'Philippines',
       recipientRel:'',
       purposeOfTxn:'',
       recipientAccount:'',
       showCountryForNationality: false,
       receiptProvider:'',
       phpRate:'',
       xchgRate:''


      };
    },
    computed: {
      vatAmount: function () {
          return (this.amountDue * this.vatRate) / 100;
      },
      // totalAmountFiatPlusVat: function () {
      //     return this.amountDue + this.vatAmount;
      // },
      
      cartItems(){
        return this.$store.state.cartItems;
      },
      cart() {
        return this.$store.state.cart;
      },
      checkout(){
        return this.$store.state.checkout;
      },
      init() {
        return this.$store.state.init;
      },
    
      
    },
      created: function(){
      let loaded = JSON.parse(localStorage.getItem('cartItems'));
  
      if(loaded) {
        this.cart.product = loaded.product;
        this.cart.fromCurrency = loaded.fromCurrency;
        this.cart.toCurrency = loaded.toCurrency;
        this.cart.image = loaded.image;
        this.userId = loaded.userId;
        this.cart.lockedPrice = loaded.price;
        this.cart.fee = loaded.fee;
        this.cryptoReceive = loaded.cryptoToReceive;
        this.amountDue = loaded.totalFiat;
        this.cryptoBought = loaded.cryptoInput;
        this.cartTimer = localStorage.getItem("timer")
        }
      //  console.log(localStorage.getItem('cartItems'))
  
  
    },
    methods: {
      toggleCountryListForNationality(){
        this.showCountryForNationality = !this.showCountryForNationality;
      },
      selectCountryForNationality: function(c) {
				this.showCountryForNationality = false;
        this.recipientNationality = c.country
			},
      getMobileCodes: function() {
      this.axios.get('/assets/mobilecodes.json')
        .then((response) => {
          this.list.mobileCodes = response.data;
        }).catch((err) => {
          console.log(err);

        })
    },
      startTimer() {
                  this.timer = setInterval(() => {
                    this.countdown--;
                    if (this.countdown === 0) {
                      clearInterval(this.timer);
                      this.lockedPriceReset = true
                      setTimeout(()=>{
                        this.$router.push({name: 'Dashboard'})
                      },5000)
                    
                    }
                  }, 1000);
      },
      onSelectRecipientAcct(event){
        const recipientAccount = event.target.value;
         this.recipientAccount = recipientAccount

      },
      openLogout(){
        this.showLogoutModal = !this.showLogoutModal;
      },
       exitModal() {
            this.showExitModal = !this.showExitModal;
        },
        onChange(event){
          const selectedPayment = event.target.value;
          this.selectedPayment = selectedPayment
       //   console.log(this.selectedPayment)
          this.getMethodsPerProvider();
        },
        onSelect(event){
            const selectedMethod = event.target.value;
            this.selectedMethod = selectedMethod
        },
       onSelectBank(event){
         const bankDetails = event.target.value;
         this.bankDetails = bankDetails
         console.log(this.bankDetails)

       },
        clearError(){
          this.error.walletAddress = ""
          this.error.msg = ""
        },
        getProvider(){
          const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            url: '/pub/buycrypto/provider',
          };

              this.axios(options)
                .then((response) => {
                  if(this.cart.toCurrency === 'PHP'){
                    this.list.providers = response.data
                  }
                  
          })

        },
        getMethodsPerProvider(){
            const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            
            url: '/pub/buycrypto/provider/methods?provider=' + this.selectedPayment,
          };

              this.axios(options)
                .then((response) => {
                  console.log(this.list.methods)
                 
                  if(this.cart.toCurrency === 'PHP'){
                    this.list.methods = response.data
                  }
                    
          })


        },

        initCheckout(){
          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            url: '/cx/txn/buycrypto/init',
          };

              this.axios(options)
                .then((response) => {
                  
                  this.txnControlId = response.data.txnControlId
           //       console.log(this.txnControlId)
                  
                  })
          },
         
          getPhpRate(){
                   
                    const params = {
                            amount: this.amountDue,
                            fromCurrency: this.cart.toCurrency,
                            toCurrency: 'PHP'
                        }

                        const data = Object.keys(params)
                            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                            .join('&');
                        

                                const options = {
                                method: 'POST',
                                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                                data,
                                url: '/pub/getxchgrate',
                                };


                                this.axios(options)
                                .then((response) => {  
                               this.phpRate = response.data.convertedAmount
                              this.xchgRate = response.data.xchgRate

                               console.log(response.data)
                               
                                }).catch((err) => {

                        })
          },
          proceedToPayment(){
            let hasError = false;

             if(this.walletAddress == '' ) {
                 this.error.walletAddress = "This is required."
                 hasError = true
                  setTimeout(()=>{
                    this.clearError()
                  },4000);
               }
              
               if(this.selectedPayment == 'BANK' ) {
                if( this.bankDetails == '' ){
                  this.error.msg = "Select your preferred bank."
                 hasError = true
                  setTimeout(()=>{
                    this.clearError()
                  },4000);
                }
               }
          

            if(!hasError ){

              const params = {
                
                paymentMethod: this.selectedMethod,
                sourceCurrency: this.cart.fromCurrency,
                targetCurrency: this.cart.toCurrency,
                lockedPrice: this.cart.lockedPrice,
                walletAddress: this.walletAddress,
                txnComment: this.comment,
                txnControlId: this.txnControlId,
                provider: this.selectedPayment,
                recipientName: this.recipientName,
                recipientNationality: this.recipientNationality,
                recipientRel: this.recipientRel,
                purposeOfTxn: this.purposeOfTxn
              }
             
              if(this.cart.toCurrency == 'USD'){
                params.sourceAmount = this.phpRate
              }
              else {
                params.sourceAmount = this.amountDue
              }
              if(this.amountDue < 50000){
                  params.recipientName = 'not applicable'
                  params.recipientNationality = 'not applicable'
                  params.recipientRel = 'not applicable',
                  params.recipientAccount = 'not applicable'
                  params.purposeOfTxn = 'not applicable'
              }else{
                  params.recipientName = this.recipientName,
                  params.recipientNationality = this.recipientNationality,
                  params.recipientRel = this.recipientRel,
                  params.purposeOfTxn = this.purposeOfTxn
              }
              if(this.recipientAccountOther != ''){
                params.recipientAccount = this.recipientAccountOther
              } else{
                params.recipientAccount = this.recipientAccount
              }
              console.log(params.recipientAccount)

            //  if(this.selectedPayment == 'DRAGONPAY'){
            //   params.paymentMethod = 'GCASH'
            // }
            if(this.selectedPayment == 'BANK'){
              params.paymentMethod = 'BANK'
            }
            if(this.selectedPayment == 'BANK-USD'){
              params.paymentMethod = 'BANK'
            }

              localStorage.setItem('checkoutDetails', JSON.stringify(params));
              
                  

              const options = {
              method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                params,
                url: '/cx/txn/buycrypto/submit',
              };

              this.axios(options)
              .then((response) => {
               // console.log(response.data)
                this.error.walletAddress = ""
                  
                   this.onConfirm();
                
                
              }).catch((error) => {
             //   console.log(error.response.data)
                this.error.walletAddress = ""

                 this.error.show = true;
                   this.error.msg = error.response.data.msgText
                  if(error.response.data.fieldErrors){
                  this.error.show = true;
                   this.error.msg = error.response.data.fieldErrors[0].defaultMessage
              }
                

                setTimeout(()=>{
                  this.clearError()
                },3000);

              })
          }

        },
        onConfirm(){
           const params = {
             txnControlId: this.txnControlId
           }
           const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
            params,
            url: '/cx/txn/buycrypto/confirm',
          };

              this.axios(options)
                .then((response) => {
                  
                  this.txnId = response.data.txnId
                  this.state = response.data.paymentStatus
                  this.paymentMethod = response.data.paymentMethod

              //    console.log(response.data)
                    if(response.data.paymentMethod == 'BANK' && this.state == 'PENDING'){
                      this.showPending = true
                       this.getTxnDetails();
                
                    } else {
                          window.location.href = (response.data.redirectUrl)
                    }

                     history.pushState(null, null, location.href);
                      window.onpopstate = function () {
                          history.go(1);
                      };
                  }).catch((error) => {
             //   console.log(error.response.data)
                this.error.walletAddress = ""

                 this.error.show = true;
                   this.error.msg = error.response.data.msgText
              if(error.response.data.fieldErrors){
                  this.error.show = true;
                   this.error.msg = error.response.data.fieldErrors[0].defaultMessage
              }
                

                setTimeout(()=>{
                  this.clearError()
                },3000);

              })
          },

            getTxnDetails(){
              const params = {
                txnId: this.txnId
              }
              const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                params,
                url: '/cx/txn/buycrypto/details',
              };
               this.axios(options)
                .then((response) => {
             //     console.log(response.data)
                  this.receiptReferenceId = response.data.txnId
                  this.receiptLockedPrice = response.data.lockedPrice
                  this.receiptFiat = response.data.targetCurrency
                  this.receiptFiatAmount = response.data.sourceAmount
                  this.receiptPaymentMethod = response.data.paymentMethod
                  this.receiptPaymentDate = response.data.paymentDate
                  this.receiptPaymentStatus = response.data.paymentStatus
                  this.receiptCrypto= response.data.sourceCurrency
                  this.receiptCryptoAmount = response.data.targetAmount
                  this.receiptWalletAddress = response.data.walletAddress
                  this.receiptComment = response.data.txnComment
                  this.recipientAccount = response.data.recipientAccount
                  this.recipientNationality = response.data.recipientNationality
                  this.recipientRel = response.data.recipientRel
                  this.recipientName = response.data.recipientName
                  this.purposeOfTxn = response.data.purposeOfTxn
                  this.receiptTxnDate = response.data.txnDate
                  this.receiptProvider = response.data.provider
                })
            },
          done(){
            this.$router.push({name: 'TransactionHistory'})
            
          },
          formatDate: function(date) {
            return moment(date).format('MM/DD/yyyy hh:mm:ss A');
          },
          formatAmountCrypto: function(amount) {
              return numeral(amount).format('0,0.000000') 
          },
          getProfile: function(){
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
                url: '/cx/profile/',
              };

              this.axios(options)
                .then((response) => {
                  
                  this.init.profile = response.data;
                  this.status = response.data.verificationStatus
             //     console.log(response.data)

                  })
          },

           checkSession: function() {

            const options = {
              method: 'POST',
              headers: { 'content-type': 'application/x-www-form-urlencoded', 'blox-authz': localStorage.getItem("tokenId") },
              url: '/cx/sessioncheck',
            };

            this.axios(options)
              .then((response) => { 
                this.getProfile();
                this.init.hasSession = true;
                  setTimeout( () => {
                    this.showLoading = false;
                  },1000)
                }).catch((error) => {
                    this.init.hasSession = false;
                  if(error.response.data.msgCode == '00001') {
                    window.location.href = "/#/";
                  }  

                  if(error.response.data.msgCode == '00020') {

                    setTimeout( () => {
                    window.location.href = "/#/setup-profile";
                  },1000)
                    
                  }  

                })
          },
        
          logout: function(){
              this.axios.get('/cx/logout')
              .then((response) => { 
                
                window.location.href = "/#/";
                  
              }).catch((error) => { 
                console.log(error)
//
              })
          },
           
     
      formatAmount: function(amount) {
              return numeral(amount).format('0,0.00') 
        },
       preventNav(event) {
              if (!this.isEditing) return
              event.preventDefault()
              event.returnValue = ""
        },
     
      
      
    },
   
     beforeMount(){
       this.checkSession();
        window.addEventListener("beforeunload", this.preventNav)
      },
    beforeDestroy() {
      window.removeEventListener("beforeunload", this.preventNav);
      clearInterval(this.timer);
    },

    beforeRouteLeave(to, from, next) {
      if (this.isEditing) {
        if (!window.confirm("Leave without saving?")) {
            return;
         }
      }
      next();
    },
        
    
    mounted(){
        // this.startTimer();
        this.initCheckout();
        this.getProvider();
        this.getMethodsPerProvider();
        this.getMobileCodes();
        this.getPhpRate();
        if(this.amountDue >= 50000){
          this.isRequired = true
        }
       
      
    },
  
}
</script>

<style scoped>
* {
  box-sizing: border-box;
  
}
.container-fluid{
  margin-left: 20px;
}
.form-check-input{
  width: 1em !important;
  height: 1em !important;
}
/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding: 10px;

}
.row{
  margin-left: 50px;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.error-text{
  color: red !important;
}
/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
  .payment{
  display: flex;
  flex-direction: column;
 }
}
.column .star{
  width: 40rem;
  object-fit: contain;
  position: relative;
  margin: 10px auto !important;
}
  label{
    color: white;
    width: 250px;
  }
/*Modal*/
.l-modal{
  color: white;
    height: auto;
    margin: 10px auto !important;
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    border-radius: 10px;
    z-index: 2;
    padding: 30px;
  }

 .payment{
  display: flex;
 }
    .mobile-container {
        position: relative;  
    }
    .default-flag {
        width: 33px;
        margin-right:0.25em;
    }
    .btn{
        border: 1px solid #1a2169;
    }
    .btn-back{
      float: right !important;
      display: flex !important;
      justify-content: center !important;
    }
    .btn-primary{
      right: 0 !important;
    }
    .country-list {
        position: absolute;
        background: #ccc;
        color: black;
        top: 70px;
        width: 17em;
        padding: 0.5em;
        height: 12em;
        overflow: auto;
        z-index: 3;
    }
    .country-list .row {
        margin: 0 !important;
    }
.label{
  color: gray;
}
    .country-list .col-sm-6 {
      display: flex;
      padding: 0.7em;
      align-items: center;
      cursor: pointer;
      color: black;
    }
    .country-list .row:hover {
      background: darken(#1a2169, 10%);
    }
    .country-name {
      margin-left: 0.5em;
    }
    .country-name span {
        font-size: 0.9em;
    }

    .flag-list-img {
        width: 25px;
    }
   
   


.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
  .payment{
  display: inline;
 }
 .row{
  margin-left: 30px;
}
}



  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;
  width: 250px;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px !important;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
  .bank{
  display: block;
  font-size: 16px;
 }
@media all and (min-width: 35rem) and (max-width: 60rem) {
  .payment{
  display: block;
 }
  .bank{
  display: flex;
  flex-direction: column;
 }
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    width: 250px;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 250px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}

</style>